export const REDIRECT_QUERY_PARAMS_KEYS = {
  stepupRequired: 'stepuprequired',
  userProfileRef: 'user-profile-ref',
  consentId: 'consent_id',
  redirectUrl: 'redirect_url',
  email: 'email',
  rus: 'rus',
  locale: 'ui_locales',
  loginHint: 'login_hint',
  nonce: 'nonce',
  fiUserId: 'fi_user_id',
};

export const QUERY_PARAMS_KEYS = {
  consentId: 'consentId',
  userRef: 'userRef',
  stepupCompleted: 'stepupCompleted',
  fiLinked: 'fiLinked',
  error: 'error',
  code: 'code',
  fiUserId: 'fiUserId',
  idv: 'idv_status',
};
